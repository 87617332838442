// ** React Imports
import React, { ReactNode } from "react"
import { useRouter } from "next/router"
import { signIn } from "next-auth/react"

// ** Next Imports
import Link from "next/link"

// ** MUI Components
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"

// ** Third Party Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** Configs
import themeConfig from "@wildeye/frontend/src/configs/themeConfig"

// ** Layout Import
import BlankLayout from "@wildeye/frontend/src/@core/layouts/BlankLayout"
import { useLazyUserExistsQuery } from "../../store/apps/api/warmlink"

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required()
})

const defaultValues = {
  password: "admin",
  email: "admin@materio.com"
}

interface FormData {
  email: string
  password: string
}

const defaultLoginPath = "/"

const LoginPage = () => {
  const {
    register,
    setError,
    handleSubmit,
    formState: {}
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const router = useRouter()
  const { error, redirectTo } = router.query
  const hashFragment = typeof window !== "undefined" ? window.location.hash : ""
  const destination = `${(redirectTo as string) || defaultLoginPath}${hashFragment}`
  const [userExists] = useLazyUserExistsQuery()
  const onSubmit = async (data: FormData) => {
    await handleMagicLinkSignIn(data.email)
  }

  const handleMagicLinkSignIn = async (email: string) => {
    if (!email) {
      setError("email", {
        type: "manual",
        message: "Please enter a valid email address"
      })
      return
    }
    let callbackUrl
    try {
      await userExists(email).unwrap() // side effect: success or error
      callbackUrl = destination
    } catch (error) {
      callbackUrl = `/pages/account-settings/account`
    }
    const res = await signIn("email", { email, redirect: false, callbackUrl: callbackUrl })
    if (res?.error) {
      setError("email", {
        type: "manual",
        message: "Unable to send magic link"
      })
    } else {
      // Redirect to the verify email page if the magic link request succeeds
      await router.push(`/pages/auth/verify-email-v1?email=${encodeURIComponent(email)}`)
    }
  }

  return (
    <Box
      sx={{
        px: 5,
        pt: 20,
        mt: 0,
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "white",
        height: "100vh"
      }}
    >
      <Box
        sx={{
          maxWidth: "1000px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "left"
        }}
      >
        <img
          src={themeConfig.logo}
          alt='logo'
          style={{
            width: "100%",
            objectFit: "contain"
          }}
        />
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 4,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography variant='h2' sx={{ width: "100%", mb: 3 }}>
            Prosper Together
          </Typography>
          <Typography variant='body1' sx={{ width: "100%", mb: 2 }}>
            Welcome to our B2B marketplace to top SaaS products to help your business win. Build massive lifetime
            passive income free and easy using our new Warmlink and Wraplinks technology.
          </Typography>
          <Typography variant='body2' sx={{ width: "100%" }}>
            Free Membership
          </Typography>
          <Box sx={{ width: 300, mt: 5 }}>
            <TextField
              id='email'
              label='Email'
              variant='outlined'
              sx={{ width: 300, mt: 0, mb: 1 }}
              {...register("email")}
            />
            <Button variant='contained' color='warning' sx={{ width: 300, py: 1, mt: 0 }} type='submit'>
              Sign-in with Magic Link
            </Button>
            <IconButton
              disableFocusRipple
              disableRipple
              sx={{ mt: 5, width: 300, p: 0 }}
              href='/'
              component={Link}
              onClick={e => {
                e.preventDefault() // Prevent default anchor behavior
                signIn("google", { callbackUrl: destination }).then(() => {})
              }}
            >
              <img width='300' alt='Sign-in with Google' src='/images/iconify-svg/web_light_sq_ctn.svg' />
            </IconButton>
          </Box>
          {error && (
            <Alert variant='outlined' severity='error' sx={{ mt: 10, fontWeight: 600, width: "100%" }}>
              We're sorry, an error occured.
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  )
}

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

LoginPage.guestGuard = true

export default LoginPage
